import {
  css,
} from 'styled-components';
import createGetAtKey from './createGetAtKey';

const themeSelector = k => createGetAtKey(`theme.${k}`);

export {
  themeSelector as theme,
};

export const min = label => (...args) => css`
  @media (min-width: ${({
    theme,
  }) => theme.screen[label] / 16}em) {
    ${css(...args)}
  }
`;

export const max = label => (...args) => css`
  @media (max-width: ${({
    theme,
  }) => (theme.screen[label] - 1) / 16}em) {
    ${css(...args)}
  }
`;
