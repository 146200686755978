import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  isCompleted,
  getStaged,
} from '../../store/stage';
import Stack from '../../common/components/primitives/Stack';
import Spacer from '../../common/components/primitives/Spacer';
import Text from '../../common/components/base/Text';
import Skeleton from '../../common/components/Skeleton';
import Card from '../../common/components/Card';
import Image from '../../common/components/base/Image';
import Cluster from '../../common/components/primitives/Cluster';
import AnswersSheetsList from './AnswersSheetsList';
import AnswersSheetButtons from './AnswersSheetButtons';

const getTitle = ({
  t,
  recipientName,
  currentAnswersSheetState,
}) => {
  let title = t('welcome');

  if (currentAnswersSheetState === 'SUSPENDED') {
    title = t('welcomeBack');
  }

  if (recipientName) {
    title = `${title} ${recipientName}`;
  }

  return `${title}!`;
};

const getDescription = ({
  t,
  welcomeMsg,
  answersSheets,
  currentAnswersSheetId,
  currentAnswersSheetState,
}) => {
  if (isEmpty(answersSheets)) {
    return t('jwterror');
  }

  if (!currentAnswersSheetId) {
    return t('completed');
  }

  if (currentAnswersSheetState === 'COMPLETED') {
    return t('completed');
  }

  if (
    currentAnswersSheetState === 'INITIAL' ||
    currentAnswersSheetState === 'SCHEDULED'
  ) {
    return welcomeMsg;
  }

  return t('continued');
};

const Activity = ({
  recipientName,
  milestoneName,
  clinicianName,
  clinicianPhotoUrl,
  projectName,
  projectLogoUrl,
  welcomeMsg,
  answersSheets,
  currentProjectLanguage,
  currentAnswersSheetId,
  loading,
}) => {
  const {
    t,
  } = useTranslation();
  const currentAnswersSheet = useMemo(() => {
    return find(answersSheets, {
      id: currentAnswersSheetId,
    });
  }, [
    answersSheets,
    currentAnswersSheetId,
  ]);
  const currentAnswersSheetState =
    currentAnswersSheet && currentAnswersSheet.state;
  const staged = useSelector(getStaged);
  const decoratedAnswersSheets = useMemo(
    () => map(answersSheets, (answersSheet) => {
      const {
        id,
        state,
        questionnaire: {
          name,
        },
      } = answersSheet;
      const isCurrent = currentAnswersSheetId && currentAnswersSheetId === id;
      let completionRate;
      if (isCurrent) {
        completionRate = (staged[id] && staged[id].completionRate) || 0;
      }
      return {
        id,
        name,
        state: isCompleted(staged)(answersSheet) ? 'COMPLETED' : state,
        isCurrent,
        completionRate,
      };
    }),
    [
      answersSheets,
      currentAnswersSheetId,
      staged,
    ],
  );
  return (
    <Stack>
      <Spacer>
        <Stack space={0}>
          <Text.Paragraph importance="high">
            {loading ? (
              <Skeleton width={200} />
            ) : (
              getTitle({
                t,
                recipientName,
                currentAnswersSheetState,
              })
            )}
          </Text.Paragraph>
          <Text.Paragraph
            size="small"
            importance="low"
          >
            {loading ? (
              <Skeleton />
            ) : (
              getDescription({
                t,
                welcomeMsg,
                answersSheets,
                currentAnswersSheetId,
                currentAnswersSheetState,
              })
            )}
          </Text.Paragraph>
        </Stack>
      </Spacer>
      <Card loading={loading}>
        {currentAnswersSheetId && (
          <Stack space={2}>
            {milestoneName && (
              <Cluster justify="space-between">
                {milestoneName && (
                  <Stack>
                    <Text.Paragraph
                      size="small"
                      importance="low"
                    >
                      Milestone
                    </Text.Paragraph>
                    <Text.Span size="large">{milestoneName}</Text.Span>
                  </Stack>
                )}
                <Cluster>
                  {clinicianPhotoUrl && (
                    <Image
                      src={clinicianPhotoUrl}
                      alt={clinicianName || 'Clinician photo'}
                    />
                  )}
                  {projectLogoUrl && (
                    <Image
                      src={projectLogoUrl}
                      alt={projectName || 'Project logo'}
                      height="150px"
                      width="300px"
                    />
                  )}
                </Cluster>
              </Cluster>
            )}
            <Stack>
              <AnswersSheetButtons
                currentProjectLanguage={currentProjectLanguage}
                answersSheetId={currentAnswersSheetId}
              />
              <AnswersSheetsList answersSheets={decoratedAnswersSheets} />
            </Stack>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

Activity.propTypes = {
  welcomeMsg: PropTypes.string,
  currentProjectLanguage: PropTypes.string,
  recipientName: PropTypes.string,
  milestoneName: PropTypes.string,
  projectName: PropTypes.string,
  projectLogoUrl: PropTypes.string,
  clinicianName: PropTypes.string,
  clinicianPhotoUrl: PropTypes.string,
  answersSheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      state: PropTypes.string,
      completionRate: PropTypes.number,
    }),
  ),
  currentAnswersSheetId: PropTypes.string,
  loading: PropTypes.bool,
};

Activity.defaultProps = {
  welcomeMsg: null,
  currentProjectLanguage: null,
  recipientName: null,
  milestoneName: null,
  projectName: null,
  projectLogoUrl: null,
  clinicianName: null,
  clinicianPhotoUrl: null,
  answersSheets: [],
  currentAnswersSheetId: null,
  loading: false,
};

export default Activity;
