// NOTE: The hard limit is ~963, since we are usually indexing together with
//       questionId, projectId and even questionnaireId. Setting the limit at 900,
//       should give us enough room in case we need to introduce additional fields.

// FIXME: Temporarily overwrite the limit due to issues with PW.
export const ANSWER_VALUE_MAX_LENGTH = 20000;

// primitive types ...
export const ANSWER_VALUE_TYPE__NUMBER = 'number';
export const ANSWER_VALUE_TYPE__STRING = 'string';
export const ANSWER_VALUE_TYPE__BOOLEAN = 'boolean';
export const ANSWER_VALUE_TYPES = [
  ANSWER_VALUE_TYPE__STRING, // the first is the default one
  ANSWER_VALUE_TYPE__NUMBER,
  ANSWER_VALUE_TYPE__BOOLEAN,
];

export const NULL_ANSWER__NO_INFORMATION = 'NO_INFORMATION';
export const NULL_ANSWER__UNKNOWN = 'UNKNOWN';
export const NULL_ANSWER__MASKED = 'MASKED';
export const NULL_ANSWER__NOT_APPLICABLE = 'NA';
export const NULL_ANSWERS = [
  NULL_ANSWER__NO_INFORMATION,
  NULL_ANSWER__UNKNOWN,
  NULL_ANSWER__MASKED,
  NULL_ANSWER__NOT_APPLICABLE,
];

export const NULL_ANSWER_OPTIONS = [
  {
    value: NULL_ANSWER__NO_INFORMATION,
    label: 'No information',
  },
  {
    value: NULL_ANSWER__UNKNOWN,
    label: 'Unknown',
  },
  {
    value: NULL_ANSWER__MASKED,
    label: 'Masked',
  },
  {
    value: NULL_ANSWER__NOT_APPLICABLE,
    label: 'Not applicable',
  },
];

export const QUESTION_CHUNK__OTHER_FIELD = 'otherField';
export const QUESTION_CHUNK__TEXT_FIELD_1 = 'textField1';
export const QUESTION_CHUNK__TEXT_FIELD_2 = 'textField2';
export const QUESTION_CHUNKS = [
  QUESTION_CHUNK__OTHER_FIELD,
  QUESTION_CHUNK__TEXT_FIELD_1,
  QUESTION_CHUNK__TEXT_FIELD_2,
];

export const QUESTION_CHUNK_FIELDS = [
  {
    id: QUESTION_CHUNK__OTHER_FIELD,
    name: 'other',
  },
  {
    id: QUESTION_CHUNK__TEXT_FIELD_1,
    name: 'text1',
  },
  {
    id: QUESTION_CHUNK__TEXT_FIELD_2,
    name: 'text2',
  },
];
