import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

const isNotNil = x => !isNil(x);

function cleanEmptyValues(object) {
  let newObject;
  if (isArray(object)) {
    newObject = filter(map(object, cleanEmptyValues), isNotNil);
  } else if (isPlainObject(object)) {
    newObject = omitBy(mapValues(object, cleanEmptyValues), isNil);
  } else {
    return object;
  }
  if (isEmpty(newObject)) {
    return null;
  }
  return newObject;
}

export default cleanEmptyValues;
