import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import includes from 'lodash/includes';
import i18next from 'i18next';
import {
  initReactI18next,
} from 'react-i18next';

const ENGLISH_VALUE = 'en';

const supportedLanguages = [
  'ar',
  'en',
  'he',
  'ta',
  'ms',
  'zh',
  'pl',
];

const textDirectionOptions = {
  ltr: [
    'en',
    'ta',
    'ms',
    'zh',
    'pl',
  ],
  rtl: [
    'ar',
    'he',
  ],
};

const maybeUpdateTextDirection = value => find(textDirectionOptions, (languages, direction) => (includes(languages, value)
  ? document.body.classList.add(direction)
  : document.body.classList.remove(direction)));

const getNavigatorLanguage = () => {
  if (typeof navigator !== 'undefined') {
    return navigator.language && navigator.language.substr(0, 2);
  }
  return null;
};

const getSupportedLanguage = (language = getNavigatorLanguage()) => {
  if (supportedLanguages.indexOf(language) >= 0) {
    return language;
  }
  return ENGLISH_VALUE;
};

export const getPreferredLanguage = (
  languagePreference,
  availableLanguages,
) => {
  if (isEmpty(languagePreference)) {
    return null;
  }
  const n = languagePreference.length;
  for (let i = 0; i < n; i += 1) {
    const language = languagePreference[i];
    if (includes(availableLanguages, language)) {
      return language;
    }
  }
  return null;
};

i18next.use(initReactI18next).init(
  {
    interpolation: {
      escapeValue: false,
    },
    lng: getSupportedLanguage(),
    fallbackLng: 'en',
    resources: {
      ar: {
        translation: {
          activity: 'Activity',
          welcome: 'Welcome',
          welcomeBack: 'Welcome back',
          continued:
            'Please click below to continue with the paused questionnaire.',
          completed: 'The following questionnaire has already been completed.',
          expired:
            'Your session has expired. Please follow the link you received or contact your health provider.',
          languageNotAvailable:
            'This activity is not available in your preferred language - please choose any available translation.',
          start: 'Start',
          next: 'Next',
          continue: 'Continue',
          complete: 'Confirm',
          settings: 'Settings',
          prevQuestion: 'Back',
          pause: 'Pause',
          consent: 'I Consent',
          consent_1: 'By clicking on complete I',
          consent_2: 'consent',
          consent_3: 'to the collection of my data',
          finishQuestionnairePrefix: 'Nearly there!',
          finishQuestionnaireTitle: 'Please confirm to submit your response',
          close: 'Close',
        },
      },
      en: {
        translation: {
          darkMode: 'Dark mode',
          activity: 'Activity',
          welcome: 'Welcome',
          welcomeBack: 'Welcome back',
          continued:
            'Please click below to continue with the paused questionnaire.',
          completed: 'The following questionnaire has already been completed.',
          expired:
            'Your session has expired. Please follow the link you received or contact your health provider.',
          languageNotAvailable:
            'This activity is not available in your preferred language - please choose any available translation.',
          start: 'Start',
          next: 'Next',
          continue: 'Continue',
          complete: 'Confirm',
          settings: 'Settings',
          prevQuestion: 'Back',
          pause: 'Pause',
          consent: 'I Consent',
          consent_1: 'By clicking on complete I',
          consent_2: 'consent',
          consent_3: 'to the collection of my data',
          finishQuestionnairePrefix: 'Nearly there!',
          finishQuestionnaireTitle: 'Please confirm to submit your response',
          settingsLanguage: 'Language',
          settingsFontSize: 'Adjust font size',
          settingsAnimationSpeed: 'Animation speed',
          disabled: 'Disabled',
          slow: 'Slow',
          normal: 'Normal',
          small: 'Small',
          medium: 'Medium',
          large: 'Large',
          close: 'Close',
          jwterror:
            "We are sorry but your access token is invalid. Please contact your doctor's office.",
        },
      },
      he: {
        translation: {
          activity: 'פעילות',
          welcome: 'ברוך הבא/שלום',
          welcomeBack: 'ברוך הבא שוב',
          continued: 'אנא לחץ למטה כדי להמשיך בשאלון',
          completed: 'הפיעלות המבוקשת כבר הושלמה',
          expired:
            'פג תוקפה של הפעילות הנוכחית, יש ללחוץ הקישור שהתקבל במכשיר הנייד או לפנות לגורם',
          languageNotAvailable:
            'פעילות זו אינה זמינה בשפה המועדפת עליך - בחר כל תרגום זמין',
          start: 'התחל',
          next: 'הבא',
          continue: 'המשך',
          complete: 'נגמר',
          settings: 'הגדרות',
          prevQuestion: 'אחורה',
          pause: 'הפסקה',
          consent: 'אני מסכיםמסכימה',
          consent_1: 'בלחיצה על השלמה  אני',
          consent_2: 'מסכיםמסכימה',
          consent_3: 'שנתוניי יאספו',
          finishQuestionnairePrefix: 'כמעט שם',
          finishQuestionnaireTitle: 'אנא אשר על מנת להגיש את התגובה',
          settingsLanguage: 'שפה',
          settingsFontSize: 'התאם עבור גודל',
          settingsAnimationSpeed: 'מהירות אנימציה',
          disabled: 'הופסק',
          slow: 'איט',
          normal: 'רגיל',
          small: 'קטן',
          medium: 'בינוני',
          large: 'גדול',
          close: 'סגור',
          jwterror:
            'אנו מצטערים אך אסימון הגישה שלך אינו בתוקף. אנא צרו קשר עם מזכירות הרופא שלך',
        },
      },
      ta: {
        translation: {
          activity: 'செயல்பாடு',
          welcome: 'வணக்கம்',
          welcomeBack: 'மீண்டும் உங்களை சந்திப்பதிள் மகிழ்ச்சி',
          continued: 'இடைநிறுத்தப்பட்ட கேள்வியாளருடன் தொடர கீழே கிளிக் செய்க.',
          completed: 'பின்வரும் வினாத்தாள் ஏற்கனவே பூர்த்தி செய்யப்பட்டுள்ளது.',
          expired:
            'உங்கள் அமர்வு காலாவதியாகி விட்டது. நீங்கள் பெற்ற இணைப்பைப் பின்தொடரவும் அல்லது உங்கள் சுகாதார வழங்குநரைத் தொடர்பு கொள்ளவும்.',
          languageNotAvailable:
            'நீங்கள் விரும்பும் மொழியில் இந்த செயல்பாடு கிடைக்கவில்லை - கிடைக்கக்கூடிய எந்த மொழிபெயர்ப்பையும் தேர்வு செய்யவும்',
          start: 'தொடக்கம்',
          next: 'அடுத்தது',
          continue: 'தொடர',
          complete: 'பினிஷ்',
          settings: 'அமைப்புகள்',
          prevQuestion: ' முந்தைய',
          pause: ' இடைநிறுத்தம்',
          consent: ' நான் ஒப்புக்கொள்கிறேன்',
          consent_1: 'முடிவு என்ற வாக்கியத்தை கிளிக் செய்வதன் மூலம் நான்',
          consent_2: 'ஒப்புதல்',
          consent_3: 'எனது தரவு சேகரிப்புக்கு',
          finishQuestionnairePrefix:
            'நீங்கள் முடிக்க நெருக்கமாக இருக்கிறீர்கள்!',
          finishQuestionnaireTitle:
            'உங்கள் பதிலைச் சமர்ப்பிக்க உறுதிப்படுத்தவும்',
          settingsLanguage: ' மொழி',
          settingsFontSize: ' எழுத்துரு அளவை சரிசெய்யவும்',
          settingsAnimationSpeed: ' அனிமேஷன் வேகம்',
          disabled: ' நிறுத்தி',
          slow: ' மெதுவாக',
          normal: 'இயல்பான',
          small: ' சிறிய',
          medium: 'நடுத்தர',
          large: ' பெரிய',
          close: ' நெருக்கமான',
          jwterror:
            ' எங்களை மன்னிக்கவும். உங்கள் அணுகல் குறியீடு தவறானது. தயவுசெய்து உங்கின் மருத்துவமனையை தொடர்புக்கொள்ளுந்கள்.',
        },
      },
      ms: {
        translation: {
          activity: 'Aktiviti',
          welcome: 'Selamat datang!',
          welcomeBack: 'Selamat kembali',
          continued:
            'Sila klik di bawah untuk meneruskan dengan soal selidik yang dihentikan seketika.',
          completed: 'Soal selidik berikut telah selesai.',
          expired:
            'Sesi anda telah tamat tempoh. Sila ikut pautan yang anda terima atau hubungi pembekal kesihatan anda.',
          languageNotAvailable:
            'Aktiviti ini tidak terdapat dalam bahasa pilihan anda - sila pilih terjemahan yang sedia ada',
          start: 'Mula',
          next: 'Seterusnya',
          continue: 'Teruskan',
          complete: 'Selesai',
          settings: 'Tetapan',
          prevQuestion: 'Kembali',
          pause: 'Berhenti seketika',
          consent: 'Saya bersetuju',
          consent_1: 'Dengan mengklik pada "Selesai", saya',
          consent_2: 'bersetuju',
          consent_3: 'dengan pengumpulan data saya.',
          finishQuestionnairePrefix: 'Hampir sampai!',
          finishQuestionnaireTitle:
            'Sila sahkan untuk menghantar maklum balas anda',
          settingsLanguage: 'Bahasa',
          settingsFontSize: 'Melaraskan saiz huruf',
          settingsAnimationSpeed: 'Kelajuan animasi',
          disabled: 'Dilumpuhkan',
          slow: 'perlahan',
          normal: 'biasa',
          small: 'kecil',
          medium: 'sederhana',
          large: 'besar',
          close: 'tutup',
          jwterror:
            'Maaf, token akses anda tidak sah. Sila hubungi pejabat doktor anda.',
        },
      },
      zh: {
        translation: {
          activity: 'Activity',
          welcome: '欢迎',
          welcomeBack: '欢迎回来',
          continued: '请点击以下来继续暂停的问卷。',
          completed: '下列的活动已经完成。',
          expired: '您的会话过期了。请点击您收到的链接或跟有关机构联系。',
          languageNotAvailable:
            '该活动无法使用您的首选语言进行-请选择任何可用的翻译',
          start: '开始',
          next: '下一个',
          continue: '继续',
          complete: '结束',
          settings: '设置',
          prevQuestion: '上一题',
          pause: '暂停',
          consent: '我同意',
          consent_1: '通过点击"完成"，您即将',
          consent_2: '同意',
          consent_3: '个人资料被收集。',
          finishQuestionnairePrefix: '就快完成了！',
          finishQuestionnaireTitle: '请确认以提交您的回复',
          settingsLanguage: '语言',
          settingsFontSize: '调整字体大小',
          settingsAnimationSpeed: '动画速度',
          disabled: '禁用',
          slow: '慢',
          normal: '正常',
          small: '小',
          medium: '中',
          large: '大',
          close: '关闭',
          jwterror: '抱歉，您的访问令牌已经无效。请联系您的医疗所。',
        },
      },
      pl: {
        translation: {
          activity: 'Aktywność',
          welcome: 'Witamy',
          welcomeBack: 'Witamy ponownie',
          continued:
            'Kliknij poniżej aby powrócić do zapauzowanego kwestionariusza.',
          completed: 'Ten kwestionariusz został już ukończony.',
          expired:
            'Twoja sesja wygasła. Użyj otrzymanego linku, lub skontaktuj się z właściwym przedstawicielem opieki zdrowotnej.',
          start: 'Rozpocznij',
          next: 'Następne',
          continue: 'Kontynuuj',
          complete: 'Zakończ',
          settings: 'Ustawienia',
          prevQuestion: 'Poprzednie',
          pause: 'Zapauzuj',
          consent: 'Wyrażam zgodę',
          consent_1: 'Klikając zakończ',
          consent_2: 'wyrażam zgodę',
          consent_3: 'na gromadzenie i przetwarzanie moich danych',
          finishQuestionnairePrefix: 'Wszystko gotowe!',
          finishQuestionnaireTitle: 'Możesz teraz zakończyć.',
          settingsLanguage: 'Język',
          settingsFontSize: 'Dostosuj rozmiar cznionki',
          settingsAnimationSpeed: 'Szybkość animacji',
          disabled: 'Wyłączone',
          slow: 'Wolno',
          normal: 'Normalnie',
          small: 'Mały',
          medium: 'Średni',
          large: 'Duży',
          close: 'Zamknij',
          jwterror:
            'Przykro nam ale token jest nieprawidłowy. Prosimy skontaktować się z gabinetem swojego lekarza.',
        },
      },
    },
  },
  err => err && console.error(err),
);

export {
  ENGLISH_VALUE,
  supportedLanguages,
  getNavigatorLanguage,
  getSupportedLanguage,
  maybeUpdateTextDirection,
};
