export const NOTIFICATION_AUTOMATIC_REMINDER = 'automatic_reminder';
export const NOTIFICATION_MANUAL_REMINDER = 'manual_reminder';

export const NOTIFICATION_TYPES = [
  NOTIFICATION_AUTOMATIC_REMINDER,
  NOTIFICATION_MANUAL_REMINDER,
];

export const MESSAGE_PURPOSE__REMIND_ABOUT_QUESTIONNAIRE =
  'remind_about_questionnaire';
export const MESSAGE_PURPOSE__GREET_PATIENT = 'greet_patient';
export const MESSAGE_PURPOSE__CONTINUE_QUESTIONNAIRE = 'continue_questionnaire';

export const MESSAGE_PURPOSES = [
  MESSAGE_PURPOSE__REMIND_ABOUT_QUESTIONNAIRE,
  MESSAGE_PURPOSE__GREET_PATIENT,
  MESSAGE_PURPOSE__CONTINUE_QUESTIONNAIRE,
];
