import isNaN from 'lodash/isNaN';
import find from 'lodash/find';
import compact from 'lodash/compact';
import every from 'lodash/every';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import moment from 'moment';
import {
  DEFAULT_PROJECT_FILTERS,
  DEFAULT_PROJECT_SORTING_OPTIONS,
  YEAR_MONTH_DAY,
  DOMAIN_PROJECTS,
  FILTER_TYPE__VARIABLE,
} from '../constants';
import ProjectFilter from './ProjectFilter';
import ProjectVariable from './ProjectVariable';
import BaseModel from './BaseModel';
import {
  zoneToUtcOffset,
} from '../utils/zone';
import appSettings from '../settings';

const {
  defaultTimezone = 'utc',
} = appSettings.private || {};

class Project extends BaseModel {
  constructor(doc) {
    super(doc);
    const variablesById = {};
    this.variables = (this.variables || []).map(
      rawVariable => new ProjectVariable(rawVariable, this),
    );
    this.variables.forEach((variable) => {
      variablesById[variable.id] = variable;
    });
    Object.defineProperty(this, 'variablesById', {
      value: variablesById,
    });
  }

  getName() {
    return this.name;
  }

  getDomain() {
    return this.belongsTo || `${DOMAIN_PROJECTS}${this._id}/`;
  }

  getTimezone() {
    // NOTE: The defaultTimezone is added automatically by server in project details subscription.
    return (
      this.timezone ||
      this.defaultTimezone ||
      this.constructor.getDefaultTimezone()
    );
  }

  getMomentInLocalTime(timestamp = Date.now()) {
    return this.constructor.getMomentInTimezone(this.getTimezone(), timestamp);
  }

  applyQuestionnaireDefaults(milestoneQuestionnaires) {
    const questionnaires = [];
    const byIdentifier = keyBy(this.questionnaires, 'identifier');
    forEach(milestoneQuestionnaires, (settings) => {
      const questionnaire = byIdentifier[settings.identifier];
      if (questionnaire) {
        const {
          version,
          identifier,
          navigationTypes,
        } = questionnaire;
        const defaultNavigationType = navigationTypes && navigationTypes[0];
        let {
          navigationType,
        } = settings;
        if (navigationType || !includes(navigationTypes, navigationType)) {
          navigationType = defaultNavigationType;
        }
        questionnaires.push({
          ...settings,
          version,
          identifier,
          navigationTypes,
          id: `${identifier}@${version}`,
          navigationType: settings.navigationType || defaultNavigationType,
        });
      }
    });
    return questionnaires;
  }

  // TODO: We should probably get rid of this one.
  getProjectName() {
    return this.name || '';
  }

  getProjectDescription() {
    return this.description || '';
  }

  getQuestionnaireId() {
    return this.questionnaire && this.questionnaire.id;
  }

  getQuestionnaires() {
    const questionnaires = this.questionnaires || [];
    if (this.questionnaire) {
      questionnaires.push(this.questionnaire);
    }
    return questionnaires;
  }

  getOutgoingDataSpecId() {
    return this.outgoingDataSpecId;
  }

  getQuestionnaireReference() {
    return this.questionnaire;
  }

  getDateStart() {
    return moment(
      this.dateStart && this.dateStart.yearMonthDay,
      YEAR_MONTH_DAY,
    ).toDate();
  }

  getDateEnd() {
    return moment(
      this.dateEnd && this.dateEnd.yearMonthDay,
      YEAR_MONTH_DAY,
    ).toDate();
  }

  getOrgId() {
    if (!this.ownerOrg) return '';
    return this.ownerOrg.id;
  }

  getOrgName() {
    if (!this.ownerOrg) return '';
    return this.ownerOrg.name;
  }

  getDepartmentName() {
    if (!this.ownerOrg) return '';
    return this.ownerOrg.departmentName;
  }

  getCurrentDay(currentDate) {
    const dateStart = this.getDateStart();
    return moment(currentDate).diff(dateStart, 'days') + 1;
  }

  getNumberOfDays() {
    const dateStart = this.getDateStart();
    const dateEnd = this.getDateEnd();
    return moment(dateEnd).diff(dateStart, 'days') + 1;
  }

  hasOwnerOrg() {
    return !!this.ownerOrg;
  }

  getRemainingDays() {
    return this.getNumberOfDays() - Math.max(0, this.getCurrentDay());
  }

  getRemaining() {
    if (!this.targetCount) return 0;
    return Math.max(0, this.targetCount - this.getCompleted());
  }

  getVariable(variableId) {
    return this.variablesById[variableId];
  }

  getProfileFormVariables(patientRecord) {
    return this.variables
      .filter(variable => variable.isProfileFormVariable())
      .map(variable => ({
        id: variable.id,
        value: patientRecord.getVariable(variable.id),
        readOnly: variable.isReadOnly(),
      }));
  }

  getSortingOptions() {
    return this.sortingPresets || DEFAULT_PROJECT_SORTING_OPTIONS;
  }

  getFilters() {
    return this.filters || DEFAULT_PROJECT_FILTERS;
  }

  isFinished() {
    return !!this.completed;
  }

  isActive() {
    return !!this.active;
  }

  isOngoing() {
    return this.getCurrentDay() > 0;
  }

  getTargetCount() {
    return this.targetCount || 0;
  }

  getCompleted() {
    return this.totalCompleted || 0;
  }

  getDeclined() {
    return this.totalDeclined || 0;
  }

  getInProgress() {
    return this.totalInProgress || 0;
  }

  getBoundFilters(filtersState = {}, {
    includeAllTags,
  } = {}) {
    const filters = this.getFilters().map(
      ({
        id,
        defaultState,
        type,
        settings,
        restrictedTo,
        tags,
        ...rest
      }) => {
        if (
          includeAllTags &&
          !every(includeAllTags, tag => includes(tags, tag))
        ) {
          return null;
        }
        const currentState = filtersState[id];
        return new ProjectFilter({
          ...rest,
          id,
          type,
          settings,
          tags,
          state: {
            ...defaultState,
            ...currentState,
          },
        });
      },
    );
    return compact(filters);
  }

  getSortWeights(id) {
    const filter = find(
      this.getFilters(),
      el => el.type === FILTER_TYPE__VARIABLE && el.settings.id === id,
    );
    return filter && filter.sortWeights;
  }

  getPercentOfTimeElapsed(currentDate) {
    const dateStart = this.getDateStart();
    const dateEnd = this.getDateEnd();
    const totalDays = moment(dateEnd).diff(dateStart, 'days');
    const daysSinceStart = moment(currentDate).diff(dateStart, 'days');
    const timeElapsed = Math.min(1, Math.max(0, daysSinceStart / totalDays));
    return Math.floor(100 * timeElapsed);
  }

  getPercentOfCompletion() {
    if (!this.targetCount) {
      return 0;
    }
    const ratio = Math.min(1, this.getCompleted() / this.targetCount);
    return Math.floor(100 * ratio);
  }

  usesDuplicatesDetection() {
    return !!this.duplicatesDetection && !!this.duplicatesStrategy;
  }

  shouldAllowAmendment(answersSheet) {
    // TODO: Take into account when exactly the answers sheet was completed
    //       and what's the grace period for making changes.
    return (
      !!this.amendmentsAllowed &&
      answersSheet.isCompleted() &&
      (!this.amendmentsTimeoutInSeconds ||
        answersSheet.secondsSinceCompletion() < this.amendmentsTimeoutInSeconds)
    );
  }

  getReference() {
    const {
      _id: id,
      name,
    } = this;
    return {
      id,
      name,
    };
  }

  static getFieldsForUser() {
    return {
      _id: 1,
      name: 1,
      questionnaire: 1,
      ownerOrg: 1,
      dateStart: 1,
      dateEnd: 1,
      active: 1,
    };
  }

  static getDefaultTimezone() {
    return defaultTimezone;
  }

  static getMomentInTimezone(
    timezone = this.getDefaultTimezone(),
    timestamp = Date.now(),
  ) {
    const offset = zoneToUtcOffset(timezone)(timestamp);
    if (isNaN(offset)) {
      return moment.invalid();
    }
    return moment(timestamp).utcOffset(offset);
  }
}

Project.collection = 'Projects';

export default Project;
