import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Cover from '../primitives/Cover';
import Center from '../primitives/Center';
import Header from './Header';
import Footer from './Footer';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const propTypes = {
  isCentered: PropTypes.bool,
  headerChildren: PropTypes.node,
  children: PropTypes.node,
  footerChildren: PropTypes.node,
  languageDirection: PropTypes.string,
};

const defaultProps = {
  isCentered: false,
  headerChildren: null,
  children: null,
  footerChildren: null,
  languageDirection: null,
};

const StyledMain = styled.main`
  flex: 1;

  direction: ${props => props.languageDirection || 'inherit'};
`;

const StyledBox = styled.div`
  padding: ${theme('space.3')};

  @media (max-width: 500px) {
    padding: ${theme('space.2')};
  }
`;

const Wrapper = ({
  isCentered,
  children,
}) => {
  return isCentered ? (
    <Cover hasPadding>{children}</Cover>
  ) : (
    <StyledBox>{children}</StyledBox>
  );
};

const LayoutMaster = ({
  headerChildren,
  children,
  footerChildren,
  isCentered,
  languageDirection,
}) => {
  return (
    <>
      <Header>{headerChildren}</Header>
      <StyledMain languageDirection={languageDirection}>
        <Wrapper isCentered={isCentered}>
          <Center isStretched>{children}</Center>
        </Wrapper>
      </StyledMain>
      <Footer>{footerChildren}</Footer>
    </>
  );
};

LayoutMaster.propTypes = propTypes;
LayoutMaster.defaultProps = defaultProps;

export default LayoutMaster;
