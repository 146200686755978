import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import {
  createSelector,
} from 'reselect';
import UserSelect from '../../selectors/User';
import Formula from '../../models/Formula';
import EvaluationScope from '../../models/EvaluationScope';
import QuestionCursor from '../../models/QuestionCursor';
import {
  maskHiddenFormValues,
  getFormErrors,
  getDynamicQuestionnaire,
  evaluateFormValuesAndProperties,
} from '../../utils/questionnaire';
import cleanEmptyValues from '../../utils/cleanEmptyValues';
import {
  constant,
  argument,
  reconcilingSelector,
  higherOrderSelector,
} from '../../utilsClient/selectors';
import toSelector from '../../utils/toSelector';

export const liftContextSelectors = (selectContext) => {
  const select = {};
  forEach(
    [
      'questionnaire',
      'validationErrors',
      'formValues',
      'dynamicProperties',
      'hasValidationErrors',
      'formErrors',
      'touched',
      'evaluationScope',
      'dynamicQuestionnaire',
      'questionCursor',
      'context',
      'name',
      'options',
      'meta',
    ],
    (name) => {
      select[name] = (...args) => higherOrderSelector(selectContext, context => context.select[name](...args));
    },
  );
  return select;
};

export const createDynamicQuestionnaireSelectors = ({
  selectRawFormValues,
  selectRawTouched,
  selectVariables,
  selectValidationErrors,
  selectPropertiesOverrides,
  selectQuestionnaire,
  selectSortedBy,
  selectFlatSections,
}) => {
  const selectStaticEvaluationScope = createSelector(
    Formula.createContextSelector(),
    toSelector(selectRawFormValues),
    UserSelect.getForFormulaScope,
    toSelector(selectVariables),
    toSelector(selectQuestionnaire),
    (formulaContext, formValues, users, variables, questionnaire) => new EvaluationScope({
      users,
      questionnaire,
      variables,
      context: formulaContext,
      answers: formValues,
    }),
  );

  const selectFormValuesAndDynamicProperties = reconcilingSelector(
    selectStaticEvaluationScope,
    toSelector(selectPropertiesOverrides),
    (evaluationScope, propertiesOverrides) => evaluateFormValuesAndProperties(evaluationScope, {
      propertiesOverrides,
    }),
  );

  const selectFormValues = memoize(() => createSelector(
    selectFormValuesAndDynamicProperties,
    argument(0, 'formValues'),
  ));

  const selectRootEvaluationScope = memoize(() => createSelector(
    selectStaticEvaluationScope,
    selectFormValues(),
    (staticEvaluationScope, formValues) => staticEvaluationScope.copyWithFormValues(formValues),
  ));

  const select = {
    questionnaire: constant(toSelector(selectQuestionnaire)),
    validationErrors: constant(toSelector(selectValidationErrors)),
    formValues: selectFormValues,
    dynamicProperties: memoize(() => createSelector(
      selectFormValuesAndDynamicProperties,
      argument(0, 'properties'),
    )),
    hasValidationErrors: memoize(() => createSelector(
      toSelector(selectQuestionnaire),
      toSelector(selectValidationErrors),
      select.dynamicProperties(),
      (questionnaire, formErrors, properties) => !!cleanEmptyValues(
        maskHiddenFormValues(questionnaire, formErrors, properties),
      ),
    )),
    formErrors: memoize(() => reconcilingSelector(
      toSelector(selectQuestionnaire),
      select.formValues(),
      toSelector(selectValidationErrors),
      select.dynamicProperties(),
      (questionnaire, formValues, formErrors, properties) => maskHiddenFormValues(
        questionnaire,
        getFormErrors(questionnaire, formValues, {
          properties,
          overwrite: formErrors,
        }),
        properties,
      ),
    )),
    touched: memoize(() => createSelector(
      toSelector(selectQuestionnaire),
      toSelector(selectRawTouched),
      select.dynamicProperties(),
      // TODO: Explain why exactly we need to mask here. This was causing problems
      //       because "touched" object does not have "value: []" for collections
      //       and as a result everything was masked inside collection.
      (questionnaire, touched, properties) => maskHiddenFormValues(questionnaire, touched, properties),
    )),
    evaluationScope: selectScopeKey => createSelector(
      selectRootEvaluationScope(),
      toSelector(selectScopeKey),
      (rootEvaluationScope, scopeKey) => rootEvaluationScope.getScopeForScopeKey(scopeKey),
    ),
    dynamicQuestionnaire: selectScopeKey => createSelector(
      toSelector(selectQuestionnaire),
      select.dynamicProperties(),
      toSelector(selectScopeKey),
      (questionnaire, properties, scopeKey) => getDynamicQuestionnaire(questionnaire, properties, scopeKey),
    ),
    questionCursor: (
      selectQuestionId,
      selectHierarchy,
      {
        force = false,
      } = {},
    ) => {
      const isValidScreen = (cursor) => {
        // NOTE: By convention we are only showing prologue for sections
        //       and epilogue for collections. In the future, we will probably
        //       want it to be configurable at questionnaire ui level.
        if (cursor.isEpilogue() && cursor.isSection()) {
          return false;
        }
        if (cursor.isPrologue() && cursor.isCollection()) {
          return false;
        }
        return true;
      };
      const defaultStepFilter = cursor => isValidScreen(cursor) && cursor.isVisible();
      //------------------------------------------------------------------------------
      return createSelector(
        toSelector({
          questionnaire: selectQuestionnaire,
          properties: select.dynamicProperties(),
          formValues: select.formValues(),
          questionId: selectQuestionId,
          hierarchy: selectHierarchy,
          sortedBy: selectSortedBy,
          flatSections: selectFlatSections,
        }),
        (params) => {
          if (force) {
            // NOTE: If "force" is used we seek to the given question even if it is not visible.
            return QuestionCursor.begin({
              ...params,
              stepFilter: isValidScreen,
            }).setFilters({
              stepFilter: defaultStepFilter,
            });
          }
          return QuestionCursor.begin({
            ...params,
            stepFilter: defaultStepFilter,
          });
        },
      );
    },
  };

  return select;
};
